import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import { useSiteMetadata } from "../hooks";
import Container from "../components/container";
import Feed from "../components/feed";

const TagTemplate = ({ data, pageContext }) => {
  const { title: siteTitle } = useSiteMetadata();

  const {
    tag,
    currentPage,
    prevPagePath,
    nextPagePath,
    hasPrevPage,
    hasNextPage,
  } = pageContext;

  const posts = data.allMdx.edges;
  const authorImages = data.allFile.edges;
  const pageTitle =
    currentPage > 0
      ? `All Posts tagged as "${tag}" - Page ${currentPage} - ${siteTitle}`
      : `All Posts tagged as "${tag}" - ${siteTitle}`;

  return (
    <Layout title={pageTitle}>
      <Container>
        <Feed heading={tag} posts={posts} authorImages={authorImages} />
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query TagPage($tag: String, $postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: $postsLimit
      skip: $postsOffset
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            tagSlugs
          }
          frontmatter {
            title
            date
            description
            author
            tags
            readingTime
          }
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "people" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 400, width: 400)
          }
          name
        }
      }
    }
  }
`;

export default TagTemplate;
